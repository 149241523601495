<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Statut par défaut" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter un statut par défaut</div>
              </v-row>
            </v-card-title>

            <!-- le label -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">label</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le label"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'ordre -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">ordre</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="L'ordre"
                    dense
                    v-model="ranking"
                    :rules="[rules.noDoubleRanking,]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>           

            <!-- La clé technique -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">clé technique</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="La clé technique"
                    dense
                    v-model="tid"
                    :rules="[rules.noDoubleTid,]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>           

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="mx-2 my-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { TicketingStatusService } from "@/service/conf/ticketing_status_service.js";
import { TicketingObjectSubobjectService } from "@/service/conf/ticketing_object_subobject_service.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddTicketingStatus",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,
      /** Le service des applications ticketing */
      serviceAppTicketing: null,

      /** La liste des statut par défaut */
      defaultStatus: [],
      /** la liste des applications */
      apps: [],

      /** le label */
      label: null,
      /** l'ordre */
      ranking: null,
      /** La clé technique */
      tid: null,

      /** Les règles de saisie des composants */
      rules: {
        noDoubleRanking: value => !this.checkAlreadyHasRanking(value) || "Ce rang est déjà utilisé pour un autre statut.",
        noDoubleTid: value => !this.checkAlreadyHasTid(value) || "Cette clé technique existe déjà.",
      }
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération de tous statut par défaut
        this.defaultStatus = await this.service.getAllDefaultStatus();

        // Récupération de toutes les apps ticketing
        this.apps = await this.serviceAppTicketing.getAllApps();
        console.error("liste des apps  " + JSON.stringify(this.apps, null, 4));

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    async save() {
      // enregistrement 
      if (this.entityHasChanged) {
        try {
          let entity = {};
          entity.label = this.label;
          entity.ranking = this.ranking;
          entity.tid = this.tid;

          // Sauvegarde et récupération du statut par défaut
          let newEntity = await this.service.create(entity);

          // Parcours de l'ensembledes apps ticketing
          for (let app of this.apps) {
            // Récupération des status de l'app
            let statusApp = await this.serviceAppTicketing.getAllStatusOfApplication(app);

            // Si l'app n'as pas de statut (cas d'erreur)
            if (statusApp.length == 0) {
              await this.serviceAppTicketing.associateStatutToApp(app, newEntity.id);

            } else if (this.checkAppHasDefaultStatus(statusApp)) {
              // Si l'application as des statut par défaut, on associe l'app et le statut
              await this.serviceAppTicketing.associateStatutToApp(app, newEntity.id);
            }            
          }

          this.$router.go(-1);

        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement du statut par défaut : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
    },

    /** Analyse si l'application a les status par défaut ou non */
    checkAppHasDefaultStatus(statusApp) {
      let statutDefault = true;

      // Parcours la liste des status de l'application 
      for (let status of statusApp) {
        // Tente de trouver le statut par défaut correspondant
        let found = this.defaultStatus.find((d) => d.id == status.id);

        if (!found) {
          statutDefault = false;
          break;
        }
      }

      return statutDefault;
    },

    /** Check si un des statut contient déjà le rank */
    checkAlreadyHasRanking() {
      if (!this.defaultStatus) {
        return false;
      }
      // On recherche le rank saisi
      let found = this.defaultStatus.find((a) => a.ranking.toString() === this.ranking);

      // Le rank saisi est trouvé, affichage d'une erreur
      if (found) {
        return true;
      } else {
        return false;
      }
    },

    /**  Check si un des statut de l'app contient la même clé technique */
    checkAlreadyHasTid() {
      if (!this.defaultStatus) {
        return false;
      }
      
      // on check le tid sur l'ensemble des statuts
      let found = this.defaultStatus.find((a) => a.tid == this.tid);

      if (found) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    canSave() {
      let changed = true;

      if (!this.label || this.label == "") {
        return false;
      }

      // Contrôle de saisie et d'unicité
      if (!this.ranking || this.ranking == "") {
        return false;
      } else {
        let found = this.defaultStatus.find((a) => a.ranking.toString() === this.ranking);

        if (found) {
          changed = false;
        }        
      }

      return changed;
    },
    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (this.label) {
        changed = true;
      }
      if (this.ranking) {
        changed = true;
      }
     

      return changed;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new TicketingStatusService(this.$api.getTicketingStatus());
    this.serviceAppTicketing = new TicketingObjectSubobjectService(
      this.$api.getTicketingObjectSubobject()
    );
    
    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>